import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import Col from 'react-bootstrap/Col';
import Figure from '../molecules/Figure';
import styles from '../../styles/components/templates/StaticContent.module.scss';
import { cloudflareLoader } from '../atoms/Img';

interface StaticContentProps {
  key: string;
  h1?: string;
  h2?: string;
  p?: any;
  s?: any;
  html?: any;
  columns?: any[];
  figures?: any[];
  imgs?: string[];
  children?: any;
  colBreak?: boolean;
  center?: boolean;
  narrow?: boolean;
  small?: boolean;
  hero?: boolean;
  full?: boolean;
  anchor?: string;
  className?: string;
}

export default function StaticContent(props: StaticContentProps) {
  const [loadedImages, setLoadedImages] = useState([]) as any;
  const {
    h1,
    h2,
    p,
    s,
    html,
    imgs,
    columns,
    center,
    figures,
    narrow,
    full,
    small,
    hero,
    colBreak,
    children,
    anchor,
    className,
  } = props;
  const classes = clsx(
    'row',
    styles.staticContent,
    { [styles.colBreak]: colBreak },
    { [styles.small]: small },
    { [styles.center]: center },
    { [styles.full]: full },
    { [styles.hero]: hero },
    { [styles.subh]: !!h2 },
    styles[`${className}`]
  );
  const ps = !p ? [] : typeof p === 'string' ? [p] : [...p];

  const addLoaded = (src: string) => {
    setLoadedImages([...loadedImages, src]);
  };

  return (
    <Fragment>
      {anchor && <a href={`#${anchor}`} className={anchor} />}
      <section className={classes} id={anchor || undefined}>
        {(!!h1 || !!h2) && (
          <Col md={6} className={'offset-md-1'}>
            {!!s && <small>{s}</small>}
            {!!h1 && <h1>{h1}</h1>}
            {!!h2 && <h2>{h2}</h2>}
          </Col>
        )}

        {!!ps.length && (
          <>
            <Col md={6} className={'offset-md-1'}>
              <div>
                {ps.map((el: any, i: number) => {
                  return <p key={i}>{el}</p>;
                })}
              </div>
            </Col>
            <Col xs={1} />
          </>
        )}

        {!!imgs &&
          imgs.map((img: any, i: number) => (
            <Col md={6} className={clsx(styles.img, 'offset-md-1')} key={i}>
              <Image
                // className={loadedImages.indexOf(img.src) > -1 ? styles.imgLoaded : ""}
                placeholder="blur"
                blurDataURL={img.src}
                onLoad={() => addLoaded(img.src)}
                src={img.src}
                alt={img.alt || 'image'}
                width={img.width || 1920}
                height={img.height || 800}
              />
            </Col>
          ))}

        {!!columns && (
          <>
            {(columns || []).map((col: any, i: number) => {
              const sm = col.narrow ? 4 : 8;
              const xl = 3; //narrow ? 2 : 3;
              const offset = (i + 1) % 2 ? 'offset-md-1' : '';
              return (
                <Col xs={8} sm={sm} md={3} xl={xl} key={i} className={offset}>
                  {col}
                </Col>
              );
            })}
          </>
        )}

        {!!figures && (
          <>
            {(figures || []).map((figure: any, i: number) => {
              const md = figures.length > 2 ? 2 : 3; //narrow ? 2 : 3;
              // const offset = (i % 4 === 0) ? "offset-md-1" : "";
              const offset =
                i % (figures.length + 1) === 0 ? 'offset-md-1' : '';
              return (
                <Col xs={8} md={md} key={i} className={offset}>
                  <Figure
                    full
                    medium
                    dark
                    gradient={!!figure.label}
                    label={figure.label}
                    icon={figure.icon}
                    ratio={figure.ratio}
                    img={figure.src}
                    href={figure.href}
                    title={figure.title}
                    caption={figure.caption}
                  />
                </Col>
              );
            })}
          </>
        )}

        {children}

        {!!html && (
          <>
            <Col md={6} className={'offset-md-1'}>
              <div>{html || ''}</div>
            </Col>
            <Col xs={1} />
          </>
        )}
      </section>
    </Fragment>
  );
}
