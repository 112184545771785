import { CallbacksApi } from '@hubspot/api-client/lib/codegen/automation/actions';
import Image from 'next/image';
import { privacy } from './privacy';
import { cloudflareLoader } from '../components/atoms/Img';
const pages_en = {
  'privacy-policy': privacy.en,
  /**
   * Home
   */
  home: [
    {
      stage: [
        {
          src: {
            s: '/assets/pages/home_stage_mobile.jpg',
            m: '/assets/pages/home_stage_tablet.jpg',
            l: '/assets/pages/home_stage_desktop.jpg',
          },
          caption: (
            <h2>
              The definitive global platform connecting the events industry.
            </h2>
          ),
        },
      ],
      full: true,
      h1: (
        <>
          <br />
          <br />
          Welcome to venueScout and thank you for your visit!
        </>
      ),
      children: [
        <>
          venueScout is the definitive global platform connecting professionals
          looking to organize and host events, and venue operators and service
          providers looking to capture more business. This search engine
          presents thousands of exhibition venues, congress & convention centers
          and large capacity hotels with meeting rooms worldwide. It also
          features event service providers, ranging from booth construction, to
          catering to on-site management and much more.
          <br />
          <br />
          <h4>Our top picks</h4>
        </>,
      ],
    },
    {
      full: true,
      figures: [
        {
          ratio: '4-3',
          src: '/assets/thumbs/venue-vienna@2x.png',
          title: 'Promoted: Messe Wien',
          caption: 'Flexible space solutions in the heart of Vienna',
          href: '/venue/global/global/messe-wien-exhibition-and-congress-centre',
        },
        {
          ratio: '4-3',
          src: 'https://imagedelivery.net/s7YIdaKmqJBCctzTZ8Z0Eg/515a0d54-1e7a-4de4-45ff-f2d54bfa7100',
          title: 'Promoted: HKCEC',
          caption:
            'World-class facilities for Asia’s most prestigious exhibitions and conferences',
          href: '/venue/hong-kong-sar/hong-kong/hong-kong-convention-and-exhibition-centre-(hkcec)',
        },
        {
          ratio: '4-3',
          src: 'https://imagedelivery.net/s7YIdaKmqJBCctzTZ8Z0Eg/df54da8f-d23c-48bc-d13a-7abaa5995b00',
          title: 'Promoted: Thessaloniki',
          caption: 'Attracting a wealth of events in South East Europe ',
          href: '/venue/greece/thessaloniki/thessaloniki-international-exhibition-and-congress-centre',
        },
      ],
    },
    {
      full: true,
      figures: [
        {
          ratio: '4-3',
          src: 'https://imagedelivery.net/s7YIdaKmqJBCctzTZ8Z0Eg/8ea52101-61ff-4a07-c60f-223812198b00',
          title: 'Promoted: Doha Exhibition and Conference Centre',
          caption:
            'Find out what the Doha Exhibition and Convention Center has to offer',
          href: '/venue/global/global/doha-exhibition-and-convention-center',
        },
        {
          ratio: '4-3',
          src: 'https://imagedelivery.net/s7YIdaKmqJBCctzTZ8Z0Eg/0d38f9f4-b6c0-4079-7bee-007f43d56f00',
          title: 'Promoted: Oman Convention and Exhibition Centre',
          caption:
            'A flow of meeting spaces to suit the most discerning conference and exhibition organizers',
          href: '/venue/oman/muscat/oman-convention-and-exhibition-centre',
        },
        {
          ratio: '4-3',
          src: 'https://imagedelivery.net/s7YIdaKmqJBCctzTZ8Z0Eg/33785347-6490-4c4a-e3cf-22e5621ce400',
          title: 'Promoted: Riva del Garda',
          caption:
            'A breathtaking place for transformational encounters in Italy',
          href: '/venue/italy/riva-del-garda/riva-del-garda-congress-centre',
        },
      ],
    },
    {
      full: true,
      children: [
        <>
          <br />
          <br />
        </>,
      ],
    },
    {
      full: true,
      figures: [
        {
          ratio: '4-3',
          src: '/assets/thumbs/signup-venue@2x.png',
          title: 'Sign-up and register your business',
          caption:
            'Join for a free trial in 2023 and benefit from a 30% discount in 2024.',
          href: '/promote-your-business/create/venue',
        },
        {
          ratio: '4-3',
          src: '/assets/thumbs/about@2x.png',
          title: 'More about venueScout',
          caption:
            'Here is the background of why we are doing what we are doing.',
          href: '/about',
        },
        {
          ratio: '4-3',
          src: '/assets/video.png',
          title: 'venueScout Introduction',
          caption: 'Watch a short video about venueScout.',
          href: 'https://youtu.be/sqzw-d00hqs',
          target: '_blank',
        },
      ],
    },
    {
      full: true,
      children: [],
    },
    {
      full: true,
      children: [
        <>
          <br />
          <br />
          <strong>venueScout’s</strong> goal is not only to take event planning
          to the next level by modernizing the search and evaluation experience,
          but to contribute to the events industry by creating a continuous,
          transparent feedback channel for all players.
        </>,
        <>
          <br />
          For any questions, please email us:{' '}
          <a href="mailto:info@venuescout.org.">
            <b>info@venuescout.org</b>
          </a>
          . Also, sign-up for our newsletter to stay informed about venueScout
          updates:
          <br />
          <br />
        </>,
      ],
    },

    {
      full: true,
      center: true,
      html: <></>,
    },

    {
      full: true,
      center: true,
      h2: (
        <>
          <br />
          Connect with us on Social Media
        </>
      ),
    },
  ],

  /**
   * What isvenueScout
   */
  'what-is-venueScout': [
    {
      h1: <>What is venueScout?</>,
      h2: (
        <>
          The essential tool to find the perfect venue and service provider for
          your next event.
        </>
      ),
      full: true,
      children: [
        <>
          For event organizers, meeting planners and exhibitors, venueScout is a
          one-stop tool that allows a comprehensive search for a suitable venue
          or service provider, and offers a direct channel to submit RFPs or to
          leave a business review.
        </>,
        <>
          For venues and service providers, venueScout is a digital platform
          that makes marketing and selling your business significantly easier
          and more successful. Through venueScout venues and service providers
          can reach exhibition/event organizers and meeting planners across the
          globe with a basic profile listing or an upgraded Platinum premium
          listing. With your venue or service being listed on venueScout, you
          make sure your business attracts enquiries from a global audience of
          professional event organizers and in turn allow us to provide
          comprehensive, detailed, structured quality information on venues and
          service providers worldwide.
        </>,
      ],
      imgs: [
        {
          src: '/assets/pages/whatis_1_desktop.jpeg',
        },
      ],
    },
    {
      h1: (
        <>
          <br />
          The Event Planner and Organizer Predicament
        </>
      ),
      h2: (
        <>
          Every year, thousands of event planners and organizers rush to
          research the perfect venue to host their exhibition, convention,
          incentive meeting or unique event.
        </>
      ),
      full: true,
      children: [
        <>
          The search process however can be daunting and cumbersome. Typically,
          event planners must search multiple websites and information one by
          one. In addition, each exhibition or event venue’s information is
          presented in different formats without the ability to compare.
        </>,
        <>
          With venueScout, event organizers and meeting planners can search for
          listed venues based on specific criteria, e.g. capacity, geographical
          location, services provided etc. Exhibition and event venues which
          match the search criteria are displayed showing either basic or
          premium information.
        </>,
        <>
          Exhibition and event venues that subscribe to a{' '}
          <a href="/venue-packages">premium listing</a> can share detailed, high
          quality information, have the ability to receive RFPs, and can
          directly respond to user feedback of their listing.
        </>,
        <>
          venueScout currently offers a free 1-year trial of a Platinum listing
          and a 30% discounted rate for year 2.
        </>,
      ],
      imgs: [
        {
          src: '/assets/pages/whatis_2_desktop.jpeg',
        },
      ],
    },
    {
      full: true,
      children: [
        <>
          No other platform or service in the world offers such an unparalleled
          user experience for event planners. Our aim is to provide the events
          industry with an invaluable tool to improve efficiency and to create
          business opportunities.
        </>,
      ],
    },

    {
      full: true,
      class: 'what-is-venueScout-anchor-1',
      anchor: 'the-venueScout-difference',
      h1: <>The venueScout Difference</>,
      html: (
        <>
          <div>
            <p>
              A venue or service listing on venueScout provides an exceptional
              new marketing channel to reach meeting planners and event
              organizers. Moreover,{' '}
              <b>
                venueScout listings offer far more comprehensive information and
                features than any other platform in the market today
              </b>{' '}
              such as: <br />
              <br />
            </p>

            <ul>
              <li>
                Top-line and detailed information about the venue, surrounding
                area, and event location
                <br />
                <br />
              </li>
              <li>
                Logistics, floorplans, schematics, and photos of fairgrounds,
                exhibition halls, and conference rooms
                <br />
                <br />
              </li>
              <li>
                Setup/move-in information for potential organizers and
                contractors
                <br />
                <br />
              </li>
              <li>
                Additional onsite services and F&B outlets
                <br />
                <br />
              </li>
              <li>
                Certifications, accreditations, and speciality functions and
                experiences only offered by this venue
                <br />
                <br />
              </li>
              <li>
                Ratings by visitors, exhibitors, and organizers of the venue
                along with first person feedback
                <br />
                <br />
              </li>
              <li>
                The direct channel for event planners and organizers to submit
                RFPs!
                <br />
                <br />
              </li>
              <li>
                View our different <a href="/venue-packages">package options</a>
              </li>
            </ul>
          </div>
          <div>
            <Image
              alt=""
              src="/assets/pages/about_vienna.png"
              width="870"
              height="2250"
              loader={cloudflareLoader}
            />
          </div>
        </>
      ),
    },
  ],

  /**
   * Promote your business
   */
  'promote-your-business': [
    {
      h1: <>Include your business on venueScout!</>,
      h2: (
        <>
          Get noticed and receive more business from event professionals
          worldwide.
        </>
      ),
      children: [
        <>
          The advantages of listing your venue or service on our platform are
          multifold. You can present your exhibition venue, congress/convention
          center, hotel’s meeting facilities or events service to thousands of
          event organizers and meeting planners looking to organize their next
          event. Currently venueScout offers a free 1-year trial of a Platinum
          listing and a 30% discounted rate for year 2.
          <br />
          <br />
        </>,
      ],
    },
    {
      full: true,
      h1: <>Add, manage, and promote one or multiple venues or businesses.</>,
      children: [
        <>
          Promoting your events business on venueScout is easy with just a few
          steps. Follow the link below to create an account, then you can start
          to build out your venue or service listing.
        </>,
        <>
          Within your venueScout account, you can add and manage multiple venue
          listings or other businesses such as event services.
        </>,
      ],
    },
    {
      h1: <>Still not enough?</>,
      children: [
        <>
          Make your venue listing stand out with the addition of beautiful
          photos, detailed specifications, and floorplans with our premium
          listings. Consider upgrading to a venue Platinum package. Find out
          more details:
        </>,
      ],
    },
    {
      imgs: [
        {
          src: '/assets/pages/about_2_desktop.jpeg',
        },
      ],
    },
  ],

  /**
   * About venueScout
   */
  about: [
    {
      hero: true,
      h1: <>About Us</>,
      h2: <>Meet the venueScout Team</>,
      children: [<></>],
    },
    {
      h1: <>Sales Contacts</>,
      class: 'contacts',
      narrow: true,
      children: [
        <span key={'anastasia'}>
          <figure>
            <figcaption>
              <b>North America & Mexico</b>
              <br />
              Anastasia Bisson
              <br />
              <a href="mailto:Anastasia@venuescout.org">
                Anastasia@venuescout.org
              </a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Anastasia_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'mauro'}>
          <figure>
            <figcaption>
              <b>Europe</b>
              <br />
              Mauro Arati
              <br />
              <a href="mailto:Mauro@venuescout.org">Mauro@venuescout.org</a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Mauro_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'frank'}>
          <figure>
            <figcaption>
              <b>Europe</b>
              <br />
              Frank Booms
              <br />
              <a href="mailto:Frank@venuescout.org">Frank@venuescout.org </a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Frank_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'silvio'}>
          <figure>
            <figcaption>
              <b>Europe</b>
              <br />
              Silvio Arati
              <br />
              <a href="mailto:Silvio@venuescout.org">Silvio@venuescout.org</a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Silvio_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'rosanna'}>
          <figure>
            <figcaption>
              <b>Europe</b>
              <br />
              Rosanna Zaccaria
              <br />
              <a href="mailto:Rosanna@venuescout.org">
                Rosanna@venuescout.org{' '}
              </a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Rosanna_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'wendy'}>
          <figure>
            <figcaption>
              <b>China</b>
              <br />
              Wendy Yip
              <br />
              <a href="mailto:Wendy@venuescout.org">Wendy@venuescout.org</a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Wendy_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'shaw'}>
          <figure>
            <figcaption>
              <b>Taiwan</b>
              <br />
              Shawn Lieu
              <br />
              <a href="mailto:Shawn@venuescout.org">Shawn@venuescout.org</a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Shawn_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'mimi'}>
          <figure>
            <figcaption>
              <b>Hong Kong & Macau</b>
              <br />
              Mimi Yeung
              <br />
              <a href="mailto:Mimi@venuescout.org">Mimi@venuescout.org</a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Mimi_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'ajay'}>
          <figure>
            <figcaption>
              <b>India & Middle East</b>
              <br />
              Ajay Puranik
              <br />
              <a href="mailto:Ajay@venuescout.org">Ajay@venuescout.org </a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Ajay_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'jamie'}>
          <figure>
            <figcaption>
              <b>Japan & Korea</b>
              <br />
              Jamie Kim
              <br />
              <a href="mailto:Jamie@venuescout.org">Jamie@venuescout.org</a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Jamie_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'SY'}>
          <figure>
            <figcaption>
              <b>Southeast Asia</b>
              <br />
              SY Chan
              <br />
              <a href="mailto:SY@venuescout.org">SY@venuescout.org</a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/SY_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'thorben'}>
          <figure>
            <figcaption>
              <b>South America</b>
              <br />
              Thorben Beissner
              <br />
              <a href="mailto:Thorben@venuescout.org">Thorben@venuescout.org</a>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Thorben_Headshot.png"
              width="110"
              height="110"
            />
          </figure>
        </span>,
        <span key={'info'}>
          <figure>
            <figcaption>
              <b>All other Sales Inquiries: </b>
              <br />
              <a href="mailto:info@venuescout.org">info@venueScout.org </a>
            </figcaption>
          </figure>
        </span>,
      ],
    },
    {
      class: 'members',
      h1: (
        <>
          <br />
          <br />
          Management & Board Members
        </>
      ),
      columns: [
        <span key={'ben'}>
          <figure>
            <figcaption>
              <p>
                <b>Ben Veechai </b>
                <br />
                CMO & Head of Sales
                <br />
                <a href="mailto:ben@venuescout.org">Ben@venueScout.org</a>
              </p>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Ben_Headshot.png"
              width="150"
              height="150"
            />
          </figure>
          <p>
            Ben Veechai is a marketing professional with over 19 years of
            experience in brand, digital, and event marketing. He began his
            career at the International Data Group (IDG) in San Francisco,
            California working on brands such as PC World and Macworld
            magazines. He later served as the UBM Americas Marketing Director
            for the Game Developer Conference series (GDC)—the world’s largest
            conference for videogame creators—holding events across three
            continents. Prior to joining jwc, Ben served as the Vice President
            of Marketing and Corporate Communications for UBM Asia and Informa
            Markets. In this role, Ben developed over 150 bespoke training on
            marketing & management towards coaching 400+ company marketers who
            organize 150+ trade events. He has co-authored two books The
            Introduction to B2B Marketing (2014) and Asia’s Journey to Digital
            Events (2020). Ben is a global speaker and lecturer on topics of
            marketing, event management, and wellness delivering talks in over
            30 cities across the globe ranging from keynote addresses at MICE
            Asia to CIFTIS Beijing to the Chinese University of Hong Kong.
            <br />
            <br />
          </p>
        </span>,
        <span key={'julia'}>
          <figure>
            <figcaption>
              <p>
                <b>Julia Donoghue</b>
                <br /> Marketing & Sales Manager
                <br />
                <a href="mailto:julia@venuescout.org">Julia@venueScout.org</a>
              </p>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Julia_Headshot.png"
              width="150"
              height="150"
            />
          </figure>
          <p>
            Julia has worked in international marketing for the past 17 years.
            Having graduated with a diploma in business administration from a
            German university, her first role in Marketing and Sales Support for
            Allianz Worldwide Care brought her to Dublin, Ireland. In 2009,
            Julia moved to Dubai to join the events industry as Marketing
            Executive and later on as Marketing Manager for dmg :: events,
            looking after the visitor marketing for the INDEX Interior Design
            Exhibition in Dubai and Jeddah. She took the next step in her career
            as Deputy Marketing Director for IQPC Dubai, responsible for
            marketing defence, oil & gas as well as pharma events. This role
            offered her good insight into the conference industry before
            returning to Germany after having lived abroad for almost ten years.
            Back in Germany, Julia joined Westfalenhallen Dortmund GmbH as
            Marketing Director, looking after a portfolio of 12
            exhibitions/trade shows as well as marketing the venue itself
            (exhibition centre, conference centre and restaurants on site).
            Julia is now based in Cologne, Germany and joined venueScout in
            November 2021.
            <br />
            <br />
          </p>
        </span>,
        <span key={'sebastian'}>
          <figure>
            <figcaption>
              <p>
                <b>Sebastian Witt</b>
                <br />
                COO
                <br />
                <a href="mailto:sebastian@venuescout.org">
                  Sebastian@venueScout.org
                </a>
              </p>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Sebastian_Headshot.png"
              width="150"
              height="150"
            />
          </figure>
          <p>
            Sebastian has over 6 years experience in sales and product
            management and 3 years experience as deputy head of sales
            management. He has a proven track-record of successful project
            management with a focus on strategy and IT and completion of
            management development programs.
            <br />
            <br />
            Sebastian is particularly experienced in project and sales
            management. In 2012 Sebastian received his M.Sc. in International
            Economics at the Erasmus University Rotterdam, Netherlands.
            <br />
            <br />
          </p>
        </span>,
        <span key={'jime'}>
          <figure>
            <figcaption>
              <p>
                <b>Jimé Essink</b>
                <br />
                CEO
                <br />
                <a href="mailto:jime@venuescout.org">Jime@venueScout.org</a>
              </p>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Jime_Headshot.png"
              width="150"
              height="150"
            />
          </figure>
          <p>
            Jimé has an exceptionally successful career in the global trade fair
            and conference industry. He has worked for nearly 30 years in
            leading B2B media businesses in emerging and developed markets
            throughout Asia, South America and Europe, amongst others as CEO of
            VNU Exhibitions Europe and President of VNU Exhibitions Asia. In
            November 2007 Jimé was appointed CEO of UBM Asia, where he was
            responsible for the transition of the company to become the leading
            exhibition organizer in Asia. After the merger of UBM with Informa
            in 2018, Jimé led the integration of the operations and became
            President of the combined business; he retired from Informa end of
            2019 and has joined the board of jwc in 2020.
            <br />
            <br />
          </p>
        </span>,
        <span key={'jochen'}>
          <figure>
            <figcaption>
              <p>
                <b>Jochen Witt</b>
                <br />
                Co-Founder & Board Member
                <br />
                <a href="mailto:jochen@venuescout.org">Jochen@venueScout.org</a>
              </p>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Jochen_Headshot.png"
              width="150"
              height="150"
            />
          </figure>
          <p>
            Jochen has a long and successful career in the Trade Fair Industry.
            Prior to starting his own business in 2007, Jochen was President &
            CEO of Koelnmesse for nearly 10 years, where he focused on the
            international expansion of Koelnmesse’s portfolio and the
            modernisation of the exhibition grounds in Cologne as well as new
            business development and the expansion of the service sector. Before
            joining the Trade Fair Industry, Jochen worked as a Tax Lawyer for
            BASF AG in various positions. In 1993 he was appointed President of
            the Potash Company of Canada in Toronto and in 1998 he became a
            member of the Executive Board of Wingas GmbH in Kassel, Germany.
            Jochen was Chairman of the European Chapter of UFI from 1999 to 2005
            and President of UFI in 2007. He is currently serving as a member of
            the Board of UFI. He is a member of several Advisory and Supervisory
            Boards in the Trade Fair Industry.
            <br />
            <br />
          </p>
        </span>,
        <span key={'gerd'}>
          <figure>
            <figcaption>
              <p>
                <b>Dr. Gerd Weber</b>
                <br />
                Co-Founder & Board Member
                <br />
                <a href="mailto:gerd@venuescout.org">Gerd@venueScout.org</a>
              </p>
            </figcaption>
            <Image
              alt=""
              src="/assets/contacts/Gerd_Headshot.png"
              width="150"
              height="150"
            />
          </figure>
          <p>
            Gerd studied Business Administration at the University of St.
            Gallen, Switzerland. He wrote his PhD about success factors in the
            airline industry. Since 1991 he has worked for McKinsey&Co.,
            starting as an associate and thereafter as senior project manager.
            Focus of his client work were sales and service projects in the
            assembly, telecommunication and chemical industries.
            <br />
            <br />
            In 2000 he joined Koelnmesse as Executive Vice President, where he
            was responsible for managing the venue in Cologne, the fourth
            largest venue globally. Gerd developed a new master plan for the
            facility and implemented the plan in a period of 4 years. The master
            plan comprises of new exhibition halls with 80.000 sqm exhibitions
            space, new entrances, a new congress center and the refurbishment of
            200.000 sqm in existing halls. After finishing the master plan in
            2007, Gerd took over responsibilities for new market projects of
            Koelnmesse. Since 2010 Gerd works as consultant for trade fair
            companies and real estate projects. His recent assignments in the
            trade fair industry include: Strategy development, market entry
            strategy, master plan development and organizational development.
            <br />
            <br />
          </p>
        </span>,
      ],
    },
    {
      h1: <>About the Company </>,
      children: [
        <>
          Founded in 2021 in Cologne, Germany, venueScout is currently growing
          from a small start-up to one of the world’s leading online event venue
          & services directories. Founded by members of established jwc GmbH,
          the leading consultancy for the events industry, venueScout’s mission
          is to improve the events industry by modernizing the search and
          evaluation process for event organizers and meeting planners sourcing
          venues and services.
        </>,

        <>
          By listing event venues of all sizes and from all parts of the world,
          venueScout seamlessly connects millions of event professionals with
          venue operators and service providers globally. Event professionals
          are able to find locations and services for their planned event
          according to their exact needs, as our listings range from
          multi-thousand sqm convention and exhibition centers to small meeting
          rooms in hotels, to even completely unique venues and locations.
        </>,
        <>
          As one of the world’s largest event venue market places for both
          established market players as well as newbies, venueScout enables
          venue operators and service providers around the world to reach a
          global audience and to grow their businesses.
        </>,
        <>
          Beside detailed venue profiles, venueScout also provides venue ratings
          from exhibitors, visitors, delegates and anybody who would like to
          share an experience on a particular venue or event service experience.
          This way venueScout brings a whole new transparency to the events
          industry and helps improve quality standards.
        </>,
      ],
      // imgs: [
      //   {
      //     src: '/assets/pages/about_2_desktop.jpeg',
      //   },
      // ],
    },
    {
      h1: (
        <>
          <br />
          General Contact Information
        </>
      ),
      narrow: true,
      columns: [
        <>
          <h2>Name & Address</h2>
          <p>
            <b>venueScout GmbH</b>
            <br />
            Am Roemerturm 1
            <br />
            50667 Cologne
            <br />
            Germany
          </p>
          <p>
            <b>CEO:</b> Jimé Essink
            <br />
            <b>COO:</b> Sebastian Witt
          </p>
        </>,
        <>
          <h2>Contact</h2>
          <p>
            <b>Fax:</b> +49 (0)221 27 25 89 65
            <br />
            <b>Email:</b>{' '}
            <a href="mailto:info@venuescout.org">info@venuescout.org</a>
            <br />
            <br />
          </p>
          <h2>Company Registration</h2>
          <p>
            Commercial Register / HRB: <b>108718</b>
            <br />
            VAT Tax Registration Number: <b>215/5844/3428</b>
            <br />
            VAT Identification Number: <b>DE VAT DE348605043</b>
            <br />
            <br />
            <br />
            <br />
          </p>
        </>,
      ],
    },
  ],

  /**
   * Partners
   */
  partners: [
    {
      full: true,
      class: 'partners',
      h1: <>venueScout is proud to work with the following partners:</>,
      html: (
        <>
          <div>
            <small> Official Partner</small>
            <figure>
              <a
                href="https://tsnn.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  alt=""
                  src="/assets/logos/tsnn.svg"
                  width="200"
                  height="200"
                />
              </a>
              <figcaption>
                <p>
                  <a
                    href="https://tsnn.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b> TSNN</b>
                  </a>{' '}
                  <b> - THE # 1 ONLINE TRADE SHOW NEWS SOURCE FOR 25 YEARS</b>
                  <br />
                </p>
              </figcaption>
            </figure>
            <p>
              We offer the highest-quality news content. As the #1 trade show
              news source, TSNN constantly delivers objective, hard-hitting
              industry news on every aspect of the trade show and convention
              industry.
            </p>
            <p>
              Our trade show news, positive blogs posts and fresh industry
              podcasts are known in the global trade show industry as the place
              to get informed, track trends and stay educated. There are more
              eyes looking at TSNN than any other trade show publication. Our
              online, digital and social content revolves around helping event
              professionals do their jobs with excellence. Visitors come to TSNN
              to get the latest updates—from show announcements to convention
              center developments to people appointments to the latest
              legislation to technology trends to sustainability efforts and
              more. We also mix in easy-to-digest pieces, such as creative
              exhibiting ideas, giving back initiatives, engagement techniques
              and the latest food and beverage display trends. Tied in closely
              with our website and helpful blogs are to-the-point, visually
              appealing newsletters as well as engaging social media accounts.
            </p>
            <p>
              TSNN reaches C-level for-profit trade show organizers,
              associations, exhibit managers, venues and suppliers; a combined
              reach of over 160,000 subscribers each month.
            </p>

            <p>
              Arlene Shows - Marketing Director,{' '}
              <a
                href="https://tsnn.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                TSNN
              </a>
              <br />
              <a href="mailto:ashows@tarsusus.com">
                ashows@tarsusus.com
              </a> / <a href="tel:+001 603-630-0125">+001 603-630-0125</a>
              <br />
              Are we connected on LinkedIn yet? If not, click{' '}
              <a
                href="https://www.linkedin.com/in/arlene-shows-mba-3905178/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          </div>
          <div>
            <small> Official Partner</small>
            <figure>
              <a
                href="https://www.ifesnet.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  alt=""
                  src="/assets/logos/ifes.png"
                  layout="fixed"
                  objectFit="contain"
                  width="200"
                  height="200"
                />
              </a>
              <figcaption>
                <p>
                  <b>About IFES:</b>
                  <br />
                </p>
              </figcaption>
            </figure>
            <p>
              The International Federation of Exhibition & Event Services (IFES)
              is the worldwide trade association dedicated to the specific needs
              of exposition designers and providers of tradeshow and events
              services. Almost 250 companies representing more than 40 countries
              are proud members of the network. The purpose of IFES is to
              promote the commercial, professional and socio-political interests
              of its members on an international level as well as generating and
              promoting a business and knowledge exchange platform for its
              members who are active in the international market. At the same
              time IFES membership is a seal of quality since each member has to
              agree on following the IFES Code of Conduct.
            </p>
            <p>
              The IFES mission is to create partner unity and to generate a
              stronger understanding about exhibition and event marketing on a
              world scale through networking and sharing knowledge.
            </p>
            <p>
              For more information about the International Federation of
              Exhibition and Event Services, go to{' '}
              <a
                href="https://www.ifesnet.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.ifesnet.com
              </a>
            </p>
            <p>
              International Federation of Exhibition Services
              <br />
              Rue de l’Amazone 2
              <br />
              1050 Brussels
              <br />
              Managing Director: Uta Goretzky
              <br />
              Email: <a href="mailto:events@ifesnet.org">events@ifesnet.org</a>
            </p>
          </div>
        </>
      ),
    },
  ],

  /**
   * Contact Us
   */
  contact: [
    {
      hero: true,
      h1: <>Contact us</>,
      h2: <>How can we help?</>,
      children: [
        <>
          Get in touch with the <a href="/about">venueScout Team</a>, send us an{' '}
          <a href="mailto:info@venuescout.org">email</a> or use the{' '}
          <a
            href="http://venuescout-20844017.hs-sites.com/contact_us"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Form
          </a>{' '}
          - no matter what your query is.
          <br />
        </>,
      ],
    },
    {
      imgs: [
        {
          src: '/assets/pages/about_2_desktop.jpeg',
        },
      ],
      columns: [
        <>
          <h2>Name & Address</h2>
          <p>
            <b>venueScout GmbH</b>
            <br />
            Am Roemerturm 1
            <br />
            50667 Cologne
            <br />
            Germany
          </p>
          <p>
            <b>CEO:</b> Jimé Essink
            <br />
            <b>COO:</b> Sebastian Witt
          </p>
        </>,
        <>
          <h2>Contact</h2>
          <p>
            <b>Fax:</b> +49 (0)221 27 25 89 65
            <br />
            <b>Email:</b>{' '}
            <a href="mailto:info@venuescout.org">info@venuescout.org</a>
            <br />
            <br />
          </p>
          <h2>Company Registration</h2>
          <p>
            Commercial Register / HRB: <b>108718</b>
            <br />
            VAT Tax Registration Number: <b>215/5844/3428</b>
            <br />
            VAT Identification Number: <b>DE VAT DE348605043</b>
            <br />
          </p>
        </>,
      ],
    },
  ],

  /**
   * FAQ
   */
  faq: [
    {
      class: 'faq',
      draft: true,
      full: true,
      h1: <>FAQs</>,
      h2: <>Here are some frequently asked questions about venueScout:</>,
      html: (
        <>
          <br />
          <h2>
            <b>For Exhibition/Event Venues and Service Providers:</b>
          </h2>
          <br />
          <ol type="a">
            <h2>Registration/Sign-Up</h2>
            <li>
              Who can register for a listing on venueScout.org?
              <div>
                venueScout listings are open to exhibition and event venues and
                service providers for the events industry globally, with the
                exemption of NATO-sanctioned countries.
              </div>
            </li>
            <li>
              Why do I have to sign-up and register my account, even if I only
              want to verify my free basic venue listing?
              <div>
                We need to verify that you are able to act on behalf of the
                respective venue or service provider, and ensure that there is
                no misconduct of business. In order to do this, we need to
                verify your contact details. Thank you very much for
                understanding!
              </div>
            </li>
            <li>
              What will happen after I sign-up/register an account?
              <div>
                Here is how it works:
                <ul>
                  <li>
                    You sign-up for an account and claim an existing or create a
                    new venue or service provider listing
                  </li>
                  <li>
                    You can update any details and confirm that the information
                    included in your listing is accurate
                  </li>
                  <li>
                    When you’re ready, you publish your venue or service
                    provider page for our audience of event planners and
                    organizers
                  </li>
                </ul>
              </div>
            </li>
            <li>
              I am having trouble with the registration, can I get help?
              <div>
                Yes, of course! We are on stand-by and happy to answer any
                queries you might have. Please do{' '}
                <a href="/contact">get in touch with us</a> immediately.
              </div>
            </li>
            <li>
              Do I need a username and password to log into my venueScout
              account?
              <div>
                No, venueScout uses a “Lazy-login” verification method that
                doesn’t require a username and password for the user to access
                their account. We send you an email every time you try to log
                in, which logs you into your account automatically. This way we
                provide you a secure login every time without the need to
                remember a username or password which could be compromised.
              </div>
            </li>
          </ol>
          <ol type="a">
            <br />
            <h2>Claiming a Venue</h2>
            <li>
              What is the “Claim venue” function I see on some of the venue
              listings page?
              <div>
                The claim venue function on venueScout is designed to allow
                owners of a particular venue to be added under their account in
                order to make further updates and add content to improve that
                listing. If you see a “Claim venue” notification on a particular
                venue listing page, it means that no owner has designated this
                venue as one that they manage/oversee just yet.
              </div>
            </li>
            <li>
              Can I claim a venue or listing that does not belong to me or that
              I do manage?{' '}
              <div>
                venueScout listings work on the honor system and expects
                individuals will only claim a venue in which they or their
                organization is actually involved with. However, as mistakes
                sometimes occur, we also have a checks and balance process in
                the case venue listings are wrongfully claimed.
              </div>
            </li>
            <li>
              What happens if another individual has claimed a venue that
              belongs to me or that I want to claim?
              <div>
                If you believe want to claim a venue which has already been
                claimed we suggest you follow these steps:
                <ul>
                  <li>
                    Check with your organization if another member of your
                    company has already claimed the listing on the group’s
                    behalf{' '}
                  </li>
                  <li>
                    <a href="/contact">Write to us</a> to file an inquire
                    regarding the claimed venue
                  </li>
                  <li>
                    In the event you cannot wait for a reply, you may also opt
                    to create a new listing within venueScout of the same venue.
                    Our backend support team will be notified of the duplicate
                    listing and reconcile the manner{' '}
                  </li>
                </ul>
              </div>
            </li>{' '}
          </ol>
          <ol type="a">
            <br />
            <h2>Updating my venue details / Managing my account</h2>
            <li>
              Will I be able to update my business profile(s) at a later stage?
              <div>
                Yes, as soon as you log into your venueScout account, you are
                able to edit and publish your business listing information.
              </div>
            </li>
            <li>
              I don’t have a website, can I still be listed on venueScout.org?
              <div>
                Unfortunately not, you need to hold your own business website in
                order to qualify for a listing with venueScout.
              </div>
            </li>
            <li>
              How do I list my business(es) on venueScout.org?
              <div>
                Once you have registered for your account (please see
                instructions above), you will be able to add one or more
                business listings to venueScout by clicking on ‘Add Business +`
                in your account.
              </div>
            </li>
          </ol>
          <ol type="a">
            <br />
            <h2>Media</h2>
            <li>
              What kind of photos should I upload?
              <div>
                You should upload images that represent and sell your business
                in its best light. These could be room or hall images, façade
                images, photos of services you provided in the past – anything
                that showcases what your business is about.
              </div>
            </li>
            <li>
              How many images are displayed on my business listing page?
              <div>
                With your basic free listing on venuescout.org you are able to
                include one image for your business. Platinum profile holders
                can include up to ten images for the general image gallery as
                well as up to ten images each per published room/hall.
              </div>
            </li>
            <li>
              What else can I upload to my listing page?
              <div>
                You can upload documents in the following formats:
                pdf/ppt/pptx/doc/docx/xls/xlsx
              </div>
            </li>
          </ol>
          <ol type="a">
            <br />
            <h2>My listing on venueScout.org</h2>
            <li>
              When will my business listing be set live?
              <div>
                As soon as you hit ‘Publish’ your business listing will appear
                live on venueScout.
              </div>
            </li>
            <li>
              How can I make my business listing stand out to grow my business?
              <div>
                Whether you are a venue owner or a service provider: Make sure
                that you submit as much detailed information as possible for
                your business listing. Ensure that there are no gaps and
                accompany the ‘hard facts’ with a beautiful image.
                <br />
                <br />
                For venues: Make your listing even stand out more by upgrading
                from your free basic listing to our premium Platinum listing.
                Check out what the premium profiles includes and how your
                business will benefit from it:{' '}
                <a href="/venue-packages">Venue Packages</a>
              </div>
            </li>
            <li>
              How do I receive proposals?
              <div>
                Venue listings with a Platinum subscription as well as listed
                service providers can be contacted and receive proposals
                directly through venueScout.
              </div>
            </li>
            <li>
              What is a ‘promoted’ listing?
              <div>
                Venues that upgrade their basic listing to an upgraded Platinum
                version and make a payment for 2024 are indicated as being
                ‘Promoted’ – these listings include added functionality and
                features, rank higher in search results, show additional
                information and showcase more than one image. Check out our{' '}
                <a href="/venue-packages">upgrade options</a>.
              </div>
            </li>
            <li>
              In which order do the listings appear on venueScout.org?
              <div>
                Listings usually appear based on the search criteria of the user
                in alphabetical order and can then be further sorted. Premium
                listings will be featured more prominently with Platinum having
                priority over Basic listings.
              </div>
            </li>
            <li>
              Can users leave feedback about my business and why is feedback
              important?
              <div>
                One of the main goals for venueScout is to provide transparent
                feedback for the events industry and to allow venues and service
                providers to deal with feedback adequately. Also, a business
                that proactively responds to feedback is seen as being
                proactive. So yes, Organizers, Visitors and Exhibitors can rate
                your venue or service based on their experiences. They can judge
                certain pre-stated criteria and leave a comment, which you can
                respond to when holding a Platinum profile listing. Your
                response is also published and can be seen by users of
                venueScout. Although users can opt to leave a comment
                anonymously, they will be asked to provide their contact details
                to venueScout, so we are able to identify possible fraud.
              </div>
            </li>
            <li>
              How do I know who looked at my profile?
              <div>
                venueScout does not capture personalized user information,
                therefore the only way to know who looked at your profile is
                when you receive a request for a proposal (available for
                Platinum listing holders).
              </div>
            </li>
            <li>
              How do I cancel my listing on venueScout.org?
              <div>
                In order for you to cancel your paid listing, please notify us
                of your wish to cancel in writing at least 3 days before your
                listing renewal date.
              </div>
            </li>
          </ol>
          <ol type="a">
            <br />
            <h2>Payment</h2>
            <li>
              Which payment methods does venueScout accept?
              <div>
                We accept payment by credit card, by invoice and by Alipay.
              </div>
            </li>
          </ol>
          <ol type="a">
            <br />
            <h2>Pricing</h2>
            <li>
              How much does it cost for my business to be listed on
              venueScout.org?
              <div>
                For exhibition and event venues, we offer two types of listings:
                A basic listing, which is free-of-charge and a Platinum listing,
                which is further categorized if you are a “large” or “small”
                venue. Please go to our{' '}
                <a href="/venue-packages">venue package page</a> for more
                details.
                <br />
                <br />
                For Service Providers we offer one type of listing. The price
                depends on the geographical business offer of the service
                provider. Please find more details{' '}
                <a href="/service-provider-package">here</a>.
              </div>
            </li>
            <li>
              Are venueScouts prices per business listing or per account?
              <div>venueScout charges apply per separate business listing.</div>
            </li>
          </ol>
          <ol type="a">
            <br />
            <h2>Contact</h2>
            <li>
              How can I contact you?
              <div>
                You can reach us by email or phone. Go to our{' '}
                <a href="/contact">Contact Us</a> page for all details.
              </div>
            </li>
          </ol>
          <br />
          <br />
          <p>
            We are more than happy to provide you with advice to get your
            business listing with venueScout up and running, as well as helping
            you avoid common mistakes.
            <br />
            <br />
            <br />
            <br />
            <br />
          </p>

          {/* <li>
            <h2>
              <b>Event Organisers</b>
            </h2>
            <ol type='a'>
              <li>
                How do I find the right venue for my event?<div></div>
              </li>
              <li>
                How do I get in touch with a venue?<div></div>
              </li>
              <li>
                How do I find a service provider for my event?<div></div>
              </li>
              <li>
                Do I have to pay for using venueScout.org?<div></div>
              </li>
              <li>
                How can I rate a venue according to my personal experience?
                <div></div>
              </li>
              <li>
                Can I save my search results?<div></div>
              </li>
            </ol>
          </li> */}
        </>
      ),
    },
  ],

  /**
   * Guides & Tutorials
   */
  guides: [
    {
      hero: true,
      draft: true,
      h1: <>venuescout Guides & Tutorials</>,
      h2: <>Know more, do more with venueScout.</>,
      children: [
        <>
          Get the most out of venueScout with these step-by-step videos and
          tutorials.
          <br />
          <br />
        </>,
        <ol type="1" key="faq-child-2">
          <li>
            Promoting an event venue or events services
            <ol type="a">
              <li>
                Download our step-by-step guide on how to claim your venue (PDF)
              </li>
              <li>Watch this short video</li>
            </ol>
          </li>
          <li>
            Finding a suitable event venue or event service provider
            <ol type="a">
              <li>Download our step-by-step guide (PDF)</li>
              <li>Watch this short video</li>
            </ol>
          </li>
        </ol>,
        <>
          Contact our support team.
          <br />
          Have more questions? Email or chat with us.
        </>,
      ],
    },
  ],

  /**
   * Terms & Conditions
   */
  'terms-conditions': [
    {
      h1: <>Terms and Conditions</>,
      h2: <>Welcome to venuescout.org!</>,
      full: true,
      html: (
        <>
          <p>
            venueScout is an online directory for venues and service providers
            in the MICE industry. venueScout offers members the possibility to
            create and manage profiles, to be contacted by potential customers.
            These Terms and Conditions outline the rules and regulations for the
            use of venueScout's website, located at www.venuescout.org (the
            “website”).
          </p>
          <p>
            By accessing the website, you accept these Terms and Conditions.
          </p>
          <p>
            The following terminology applies to these Terms and Conditions,
            privacy statement and disclaimer notice and all agreements:
            "Client", "You" and "Your" refers to you, the person logging onto
            the website. “venueScout”, the "Company", "Ourselves", "We", "Our"
            and "Us", refers to our company. "Party", "Parties", or "Us", refers
            to both the client and ourselves. Any use of the above terminology
            or other words in the singular, plural, capitalization and/or he/she
            or they, are taken as interchangeable and therefore as referring to
            the same.
          </p>
          <p>
            <em>
              When you register and join the venueScout services, you become a
              member. If you have chosen not to register for our services, you
              may access certain features as a “visitor”.
            </em>
          </p>
          <br />
          <h2>Cookies</h2>
          <p>
            We employ the use of cookies. By accessing venuescout.org,
            regardless of being a member or a visitor, and accepting the use of
            certain cookies through the cookie banner, you agreed to use these
            certain cookies in accordance with venueScout's{' '}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              privacy policy
            </a>
            .
          </p>
          <br />
          <h2>License</h2>
          <p>
            Unless otherwise stated, venueScout and/or its licensors own the
            intellectual property rights for all material on venuescout.org. All
            intellectual property rights are reserved.
          </p>
          <p>You must not:</p>
          <ul>
            <li>Republish material from venuescout.org</li>
            <li>Sell, rent or sub-license material from venuescout.org</li>
            <li>Reproduce, duplicate or copy material from venuescout.org</li>
            <li>Redistribute content from venuescout.org</li>
          </ul>

          <p>
            Parts of the website offer an opportunity for users to post and
            exchange opinions and information (“comments”) in certain areas of
            the website. venueScout does not filter, edit, publish or review
            comments prior to their presence on the website. Comments do not
            reflect the views and opinions of venueScout, its agents and/or
            affiliates. Comments reflect the views and opinions of the person
            who posts their views and opinions. To the extent permitted by
            applicable laws, venueScout shall not be liable for the comments or
            for any liability, damages or expenses caused and/or suffered as a
            result of any use of and/or posting of and/or appearance of the
            comments on the website. You agree to indemnify venueScout from any
            and all claims from third parties or other users against venueScout
            arising out of your posted comments. venueScout shall at no time be
            made liable for any comments made by users.
          </p>
          <p>
            venueScout reserves the right to monitor all comments and to remove
            any comments which can be considered inappropriate, offensive or
            causes breach of these Terms and Conditions, especially the warrants
            and representations laid out hereinafter.
          </p>
          <p>You warrant and represent that: </p>
          <ul>
            <li>
              You are entitled to post the comments on the website and have all
              necessary licenses and consents to do so
            </li>
            <li>
              The comments do not invade any intellectual property right,
              including without limitation copyright, patent, or trademark of
              any third party
            </li>
            <li>
              The comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
            </li>
            <li>
              The comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity
            </li>
          </ul>

          <p>
            You hereby grant venueScout a non-exclusive license to use,
            reproduce, edit, and authorize others to use, reproduce and edit any
            of your comments in any and all forms, formats or media. You agree
            to indemnify venueScout of any and all claims of intellectual
            property right infringement from third parties or other users
            against venueScout for the reproduction of comments in accordance
            with the foregoing.
          </p>
          <br />
          <h2>Third Party Information</h2>
          <p>
            The information available on the website may contain information
            provided by third parties or from other sources or references.
            Without any obligation to do so venueScout will attempt to ensure
            that such information is up to date and accurate at the time it is
            posted on the website; however, venueScout does not verify such
            information and therefore caution should be exercised in relation to
            the reliability and accuracy of the information.
          </p>

          <br />
          <h2>Hyperlinking to our Content</h2>

          <p>
            The following organizations may link to the website without prior
            written approval:
          </p>
          <ul>
            <li>Government agencies;</li>
            <li>Search engines;</li>
            <li>News organizations;</li>
            <li>
              Online directory distributors may link to our website in the same
              manner as they hyperlink to the websites of other listed
              businesses; and
            </li>
            <li>
              System-wide accredited businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our website.
            </li>
          </ul>

          <p>
            These organizations may link to our homepage, to publications or to
            other website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party’s site.
          </p>
          <p>
            We may consider and approve other link requests from the following
            types of organizations:
          </p>
          <ul>
            <li>
              commonly-known consumer and/or business information sources;
            </li>
            <li>dot.com community sites;</li>
            <li>associations or other groups representing charities;</li>
            <li>online directory distributors;</li>
            <li>internet portals;</li>
            <li> accounting, law and consulting firms; and</li>
            <li>educational institutions and trade associations.</li>
          </ul>
          <p>
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of venueScout;
            and (d) the link is in the context of general resource information.
          </p>
          <p>
            These organizations may link to our homepage so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party’s site.
          </p>
          <p>
            If you are one of the organizations listed in the paragraph above
            and are interested in linking to the website, you must inform us by
            sending an email to venueScout. Please include your name, your
            organization name, contact information as well as the URL of your
            site, a list of any URLs from which you intend to link to the
            website, and a list of the URLs on our site to which you would like
            to link. Wait 2-3 weeks for a response.
          </p>
          <p>Approved organizations may hyperlink to the website as follows:</p>
          <ul>
            <li>By use of our corporate name; or</li>
            <li>By use of the uniform resource locator being linked to; or</li>
            <li>
              By use of any other description of our website being linked to
              that makes sense within the context and format of content on the
              linking party’s site.
            </li>
          </ul>
          <p>
            No use of venueScout's logo or other artwork will be allowed for
            linking absent a trademark license agreement.
          </p>
          <br />
          <h2>iFrames</h2>
          <p>
            Without prior approval and written permission, you may not create
            frames around our web pages that alter in any way the visual
            presentation or appearance of our website.
          </p>
          <br />
          <h2>Content Liability for Venue Pages</h2>
          <p>
            Members have the possibility of editing their respective
            organizations page on the website (“Venue Page”, Service Provider
            Page”). We shall not be held responsible for any content that
            appears on your Venue Page except for the content published by
            venueScout itself, as long as the information is not provided by you
            directly. venueScout will post information about you on your Venue
            Page which we have received from third parties or informational
            sources. Should any information on your Venue Page published by
            venueScout be inaccurate, before initiating litigation, you agree to
            notify us without any undue delay so venueScout can correct this
            information. venueScout will amend the inaccurate information within
            two weeks of your notification.
          </p>
          <p>
            Notwithstanding information posted by venueScout on your Venue Page
            not obtained by you directly you agree to indemnify us against all
            claims that arise from information on your Venue Page. No link(s)
            should appear on any Venue Page that may be interpreted as libelous,
            obscene or criminal, or which infringes, otherwise violates, or
            advocates the infringement or other violation of, any third-party
            rights.
          </p>
          <br />
          <h2>Your Privacy</h2>
          <p>
            Please read our{' '}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </p>
          <br />
          <h2>Payments</h2>
          <p>
            The services of venueScout are sold as yearly subscriptions to
            members. Upon the purchase of a subscription, you agree to using the
            services provided by venueScout for a minimum of one year. A
            termination of the subscription contract is possible only for the
            following subscription period, up to one day before the renewal
            period commences.
          </p>
          <p>
            You may be offered more than one billing option when you purchase
            our services. You agree to pay on the basis and at the rate selected
            when a purchase was submitted through your account. You also agree
            to pay all applicable taxes, government charges and foreign exchange
            fees. venueScout will periodically charge your payment method at the
            applicable rate. All amounts paid are non-refundable unless
            otherwise mandatory by applicable law. venueScout may charge
            interest for any overdue amounts at the rate of the lesser of 1% per
            month or the lawful maximum, and you agree to reimburse us for all
            collection costs for overdue amounts.
          </p>
          <br />
          <h2>Limitation on Damages</h2>
          <p>
            Users of this website agree that venueScout will not be liable to
            them or any third party for any damages that they may incur in
            relation to the use of the website or any services available
            thereon. This limitation does not apply in case of/for:
          </p>
          <ul>
            <li>intentional or grossly negligent breach of duty;</li>
            <li>
              breach of essential contractual obligations (“Kardinalpflichten”);
            </li>
            <li>injury to life, body and health;</li>
            <li>
              the assumption of a guarantee for the quality or existence of a
              performance;
            </li>
            <li>
              success or the assumption of a procurement risk according to
              section 276 of the German Civil Code (BGB);
            </li>
            <li>
              mandatory statutory liability, in particular product liability law
              (Produkthaftungsgesetz);
            </li>
            <li>
              default in the case of agreement of a fixed delivery date
              (absolutes Fixgeschäft).
            </li>
          </ul>

          <br />
          <h2>Removal of links from our website</h2>
          <p>
            If you find any link on the website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links, but we are not obligated to or so
            or to respond to you directly.
          </p>
          <p>
            We do not ensure that the information on the website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </p>
          <br />
          <h2>Changes to the website and Terms & Conditions</h2>
          <p>
            venueScout reserves the right at its sole discretion to make changes
            to the website and the Terms and Conditions. Any use of the website
            after the Terms and Conditions have been amended will be in
            accordance with the new Terms and Conditions and use of the website
            after the Terms and Conditions have been amended will confirm a
            user's acceptance of the new Terms and Conditions.
          </p>

          <br />
          <h2>Governing Law / Jurisdiction</h2>
          <p>
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of the Federal Republic of Germany. Any
            disputes that may arise out of the use of venueScout.org will be, to
            the extent legally permissible, subject to the jurisdiction of the
            courts of Cologne, Germany.
          </p>

          <br />
          <h2>Disclaimer</h2>
          <p>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to the website
            and the use of the website. Nothing in this disclaimer will:
          </p>
          <ul>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li>
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>
          <p>
            The limitations and prohibitions of liability set in these Terms and
            Conditions and elsewhere in this disclaimer: (a) are subject to the
            preceding paragraph; and (b) govern all liabilities arising under
            the disclaimer, including liabilities arising in contract, in tort
            and for breach of statutory duty.
          </p>
          <p>
            Notwithstanding any exceptions in these Terms and Conditions as long
            as the website and the information and services on the website are
            provided free-of-charge, we will not be liable for any loss or
            damage of any nature.
            <br />
            <br />
            <br />
            <br />
          </p>
        </>
      ),
    },
  ],
} as any;

const pages_zh = {
  'what-is-venueScout': [],
  about: [],
  'privacy-policy': privacy.zh,
} as any;

export const pages = {
  en: pages_en,
  zh: pages_zh,
} as any;
